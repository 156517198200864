import { mapGetters, mapActions, mapMutations } from "vuex"
import { getHeadersForTable, getFootersForTable } from "@/utils/table"
import { TABLE_NAMES, MAX_CHARACTER_LIMIT } from "@/constants"
import { VALIDATION_ERROR } from "@/constants"

export default {
  name      : "OptionLists",
  components: {
  },
  data() {
    return {
      showAddOptionListDialog     : false,
      optionListName              : undefined,
      optionListNameCharacterLimit: MAX_CHARACTER_LIMIT.OPTION_LIST_NAME
    }
  },
  computed: {
    ...mapGetters({
      isAddingOptionList: "optionLists/isAddingOptionList",
      isOptionListAdded : "optionLists/isOptionListAdded",
      optionLists       : "optionLists/optionLists",
      optionListItems   : "optionListItems/optionListItems",
      optionListAddError: "optionLists/optionListAddError"
    }),
    headersForTable() {
      return getHeadersForTable(TABLE_NAMES.OPTION_LISTS, this.$t.bind(this))
    },
    footersForTable() {
      return getFootersForTable(TABLE_NAMES.OPTION_LISTS, this.$t.bind(this))
    },
    optionListsForTable() {
      return this.optionLists.map(optionList => {
        const optionListItems = this.optionListItems
          .filter(optionListItem => optionListItem.optionListId === optionList.id)
          .map(optionListItem => optionListItem.name)

        return {
          id             : optionList.id,
          name           : optionList.name,
          optionListItems: optionListItems.length ? optionListItems.join(", ") : this.$t("741"),
          systemDefault  : optionList.systemDefault
        }
      })
    },
    isOptionListNameDuplicate() {
      if(this.optionListAddError) {
        return this.optionListAddError.field === VALIDATION_ERROR.FIELD.NAME &&
          this.optionListAddError.type === VALIDATION_ERROR.TYPE.DUPLICATE
      }
    },
    isOptionListNameValid() {
      return this.optionListName &&
      this.optionListName.length <= this.optionListNameCharacterLimit
    }
  },
  methods: {
    ...mapActions({
      addOptionList: "optionLists/addOptionList"
    }),
    ...mapMutations({
      setOptionListAddError: "optionLists/setOptionListAddError"
    }),
    cancelOptionListAdd() {
      this.optionListName          = undefined
      this.showAddOptionListDialog = false
    }
  },
  watch: {
    optionListName: {
      handler: function() {
        this.setOptionListAddError(undefined)
      }
    },
    isOptionListAdded: {
      handler: function(value) {
        if (value) {
          const optionList             = this.optionLists.find(optionList => optionList.name === this.optionListName)
          this.optionListName          = undefined
          this.showAddOptionListDialog = false
          this.$router.push({ name: "option-list", params: { id: optionList?.id } })
        }
      }
    }
  }
}
